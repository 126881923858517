<template>
  <div class="py-4">
    <div class="container">
      <h2 class="h2 text-center mb-5">聯繫方式</h2>
      <section class="contact">
        <div class="container-fluid">
          <h3 class="h3 text-center mb-5">對商品有任何問題，歡迎聯繫我們。</h3>
          <div class="row">
            <div class="col-12 col-lg-6 text-center">
              <h3 class="h3">服務專線</h3>
              <ul class="contact-list">
                <li class="contact-list-item">
                  <a class="contact-link" href="tel:+886-7-7024221"
                    >(07) 702-4221</a
                  >
                </li>
                <li class="contact-list-item">
                  <a class="contact-link" href="tel:+886-7-7024327"
                    >(07) 702-4327</a
                  >
                </li>
              </ul>
              <div class="contact-block">
                <h3 class="h3">傳真</h3>
                <h5 class="h5">(07) 702-5930</h5>
              </div>
              <div class="contact-block">
                <h3 class="h3">地址</h3>
                <h5 class="h5">高雄市大寮區鳳屏一路6號</h5>
              </div>
              <div class="contact-block">
                <h3 class="h3">營業時間</h3>
                <h5 class="h5">周一至周五 9:00 ~ 18:00</h5>
              </div>
              <div class="contact-block">
                <h3 class="h3">Email</h3>
                <a class="contact-link" to="mailto:a85752958@gmail.com"
                  >a85752958@gmail.com</a
                >
              </div>
            </div>
            <div class="col-12 col-lg-6 text-center">
              <h3 class="h3">所在位置</h3>
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3682.649744886062!2d120.3808946153406!3d22.629551036579862!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x346e1b0f39ac06e7%3A0x6ced97897b389e0b!2zODMx6auY6ZuE5biC5aSn5a-u5Y2A6bOz5bGP5LiA6LevNuiZnw!5e0!3m2!1szh-TW!2stw!4v1645160749293!5m2!1szh-TW!2stw"
                class="w-100"
                height="450"
                style="border: 0"
                allowfullscreen=""
                loading="lazy"
              ></iframe>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  name: "contact",
};
</script>
